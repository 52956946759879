/** @format */

import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import componentsStyle from "assets/jss/material-kit-pro-react/views/presentationSections/componentsStyle.js";

// import macbookImage from "assets/img/assets-for-demo/presentationViewSectionComponent/laptop-basics.png";
// import shoppingCartImage from "assets/img/assets-for-demo/presentationViewSectionComponent/table.jpg";
// import shareButtonImage from "assets/img/assets-for-demo/presentationViewSectionComponent/share-btn.jpg";
// import cardImage from "assets/img/assets-for-demo/presentationViewSectionComponent/coloured-card-with-btn.jpg";
import twitterImage from "assets/img/assets-for-demo/presentationViewSectionComponent/coloured-card.jpg";
// import iconsImage from "assets/img/assets-for-demo/presentationViewSectionComponent/social-row.jpg";
// import repostImage from "assets/img/assets-for-demo/presentationViewSectionComponent/pin-btn.jpg";
// import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import InsetList from "components/LanguageList/LanguageList";
import reactLogo from "assets/img/languageIcons/reactLogo.png";
import githubIcon from "assets/img/languageIcons/githubIcon.png";
import firebaseIcon from "assets/img/languageIcons/firebaseIcon.png";
import postgresqlIcon from "assets/img/languageIcons/postgresqlIcon.png";
import programmer from "assets/img/languageIcons/programmer.png";

const useStyles = makeStyles(componentsStyle);

export default function SectionComponents() {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.container}>
        <GridContainer justify='center'>
          <GridItem md={5} lg={5} sm={12} xs={12}>
            <h3 className={classes.title}>Skills</h3>
            <h6 className={classes.description}>
              I am always striving to expand my skill set.
            </h6>
            <InsetList />
            <h5 className={classes.description}>
              <ListItem></ListItem>
            </h5>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6} className={classes.mlAuto}>
            <div className={classes.imageContainer}>
              <img
                src={programmer}
                alt='macbook'
                className={classes.componentsMacbook}
              />
              {/* <img
                src={shoppingCartImage}
                alt='macbook'
                className={classes.shoppingCart}
              /> */}
              <img
                src={reactLogo}
                alt='macbook'
                className={classes.shareButton}
              />
              <img
                src={githubIcon}
                alt='macbook'
                className={classes.cardImage}
              />
              <img
                src={twitterImage}
                alt='macbook'
                className={classes.twitterImage}
              />
              <img
                src={postgresqlIcon}
                alt='macbook'
                className={classes.iconsImage}
              />
              <img
                src={firebaseIcon}
                alt='macbook'
                className={classes.repostImage}
              />
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
