/**
 * /*eslint-disable
 *
 * @format
 */

import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// sections for this page
import SectionDescription from "views/PresentationPage/Sections/SectionDescription.js";
import SectionComponents from "views/PresentationPage/Sections/SectionComponents.js";
// import SectionCards from "views/PresentationPage/Sections/SectionCards.js";
// import SectionContent from "views/PresentationPage/Sections/SectionContent.js";
// import SectionSections from "views/PresentationPage/Sections/SectionSections.js";
// import SectionExamples from "views/PresentationPage/Sections/SectionExamples.js";
// import SectionFreeDemo from "views/PresentationPage/Sections/SectionFreeDemo.js";
// import SectionOverview from "views/PresentationPage/Sections/SectionOverview.js";
// import SectionPricing from "views/PresentationPage/Sections/SectionPricing.js";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";

const useStyles = makeStyles(presentationStyle);

export default function PresentationPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        brand='Robert Havelaar'
        links={<HeaderLinks dropdownHoverColor='info' />}
        fixed
        color='transparent'
        changeColorOnScroll={{
          height: 400,
          color: "info",
        }}
      />
      <Parallax
        image={require("assets/img/hero.jpg")}
        className={classes.parallax}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                {/* <h1>
                  Robert Havelaar
                  <span className={classes.proBadge}>PRO</span>
                </h1> */}
                <h2 className={classes.title}>Full Stack Software Engineer</h2>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionDescription />
        <SectionComponents />
        {/* <SectionCards /> */}
        {/* <SectionContent /> */}
        {/* <SectionSections /> */}
        {/* <SectionExamples /> */}
        {/* <SectionFreeDemo /> */}
        {/* <SectionOverview /> */}
      </div>
      {/* <SectionPricing /> */}
      <Footer
        theme='white'
        content={
          <div>
            <div className={classes.left}>
              <a className={classes.footerBrand}>RMHavelaar@Gmail.com</a>
            </div>

            <div className={classes.rightLinks}>
              <ul>
                <li>
                  <Button
                    href='https://twitter.com/havelaar_robert'
                    target='_blank'
                    color='twitter'
                    justIcon
                    simple>
                    <i className='fab fa-twitter' />
                  </Button>
                </li>
                <li>
                  <Button
                    href='https://github.com/RMHavelaar'
                    target='_blank'
                    color='github'
                    justIcon
                    simple>
                    <i className='fab fa-github' />
                  </Button>
                </li>
                <li>
                  <Button
                    href='https://www.linkedin.com/in/robert-havelaar-00104578/'
                    target='_blank'
                    color='linkedin'
                    justIcon
                    simple>
                    <i className='fab fa-linkedin' />
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        }
      />
    </div>
  );
}
