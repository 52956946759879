/** @format */

import React from "react";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import { makeStyles } from "@material-ui/core/styles";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ChatIcon from "@material-ui/icons/Chat";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/presentationSections/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify='center'>
          <GridItem md={8} sm={8}>
            <h4 className={classes.description}>
              My name is Robert Havelaar and I am a passionate programmer who is
              on a journey to complete a Full Stack Software Engineering
              certificate from Kenzie Academy.{" "}
            </h4>
          </GridItem>
        </GridContainer>
        <div className={classes.features}>
          <GridContainer>
            <GridItem md={4} sm={4}>
              <InfoArea
                title='Dedication'
                description='I am a self-motivated person, with the drive to be the best I can be. I take this attitude into every project I do.  '
                icon={EmojiObjectsIcon}
                iconColor='danger'
                vertical={true}
              />
            </GridItem>
            <GridItem md={4} sm={4}>
              <InfoArea
                title='Leadership'
                description='I have 10 years of experience in leadership roles while managing teams of up to 50 people.  I was select by Vail Resorts to attend several management boot camps and training. I also understand how to ethically lead and motivate a team.'
                icon={AssignmentIndIcon}
                iconColor='primary'
                vertical={true}
              />
            </GridItem>
            <GridItem md={4} sm={4}>
              <InfoArea
                title='Scrum'
                description='Communication skills are vital in the workplace, especially for remote work. I have worked on several projects with small to medium-sized teams on SCRUM platforms such as Trello.'
                icon={ChatIcon}
                iconColor='success'
                vertical={true}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
